<template>
  <b-card>
    <form-users-workspace :editable="editable" :user_id="$route.params.id" :workspace_id="$route.params.workspace_id"/>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';
import FormUsersWorkspace from './FormUsersWorkspace.vue';
export default {
    components: {
        BCard,
        FormUsersWorkspace
    },
    data(){
        return{
        editable: true
    }}
}
</script>

<style>

</style>